import Flatpickr from 'stimulus-flatpickr'

// import the translation files and create a translation mapping
import { French } from "flatpickr/dist/l10n/fr.js"
import { english } from "flatpickr/dist/l10n/default.js"

// create a new Stimulus controller by extending stimulus-flatpickr wrapper controller
export default class extends Flatpickr {
  static targets = ["clearButton"]

  locales = {
    fr: French,
    en: english
  }

  connect () {
    // define locale and global flatpickr settings for all datepickers
    this.config = {
      locale: this.locale,
      ...this.config // this allows extending this controller
    }

    // handle click on clear button
    if (this.hasClearButtonTarget) {
      this.boundOnClearButtonClick = this.onClearButtonClick.bind(this)
      this.clearButtonTarget.addEventListener("click", this.boundOnClearButtonClick)
    }

    super.connect()
  }

  disconnect () {
    super.disconnect()

    if (this.hasClearButtonTarget) {
      this.clearButtonTarget.removeEventListener("click", this.boundOnClearButtonClick)
    }
  }

  get locale () {
    if (this.locales && this.data.has("locale")) {
      return this.locales[this.data.get("locale")]
    } else {
      return French
    }
  }

  onClearButtonClick (event) {
    event.preventDefault()
    this.fp.clear()
  }
}
