import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["min", "max", "error", "submit"]
  static values = {
    errorMessage: { type: String, default: `<code>max</code> field must be greater than <code>min</code> field.` }
  }

  validate () {
    if (this.hasErrorTarget) this.errorTarget.innerHTML = ""
    if (this.hasSubmitTarget) this.submitTarget.disabled = false
    if (!this.hasMinTarget || !this.hasMaxTarget) return

    const min = Number(this.minTarget.dataset.unformattedValue || this.minTarget.value)
    const max = Number(this.maxTarget.dataset.unformattedValue || this.maxTarget.value)
    if (!min || !max || min <= max) return

    if (this.hasErrorTarget) {
      this.errorTarget.innerHTML = `<i class="fa-light fa-triangle-exclamation"></i> ${this.errorMessageValue}`
    } else {
      alert(this.errorMessageValue)
    }

    if (this.hasSubmitTarget) this.submitTarget.disabled = true
  }
}
