import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["mapButton", "listButton", "content", "hiddenField", "currentCurrencyPrice"]
  static values = {
    buttonClass: { type: String, default: "active" },
    contentClass: { type: String, default: "map-visible" }
  }

  showMap () {
    this.mapButtonTargets.forEach(button => button.classList.add(this.buttonClassValue))
    this.listButtonTargets.forEach(button => button.classList.remove(this.buttonClassValue))
    this.contentTarget.classList.add(this.contentClassValue)
    this._setHiddenField(true)
    this.currentCurrencyPriceTargets.forEach(el => {
      el.hidden = true
    })
    setTimeout(() => {
      const mapElement = document.querySelector('[data-controller~="search-map"]')
      if (mapElement) {
        // On déclenche l'événement personnalisé qui sera capté par le contrôleur search-map
        mapElement.dispatchEvent(new Event('initializeLeaflet'))
      }
    }, 500)
  }

  hideMap () {
    this.mapButtonTargets.forEach(button => button.classList.remove(this.buttonClassValue))
    this.listButtonTargets.forEach(button => button.classList.add(this.buttonClassValue))
    this.contentTarget.classList.remove(this.contentClassValue)
    this._setHiddenField(false)
    this.currentCurrencyPriceTargets.forEach(el => {
      el.hidden = false
    })
  }

  highlightSearchResult (event) {
    this.element.dispatchEvent(new CustomEvent("highlight-search-result", {
      bubbles: true,
      cancelable: true,
      detail: {
        accommodationId: event.params.accommodationId
      }
    }))
  }

  _setHiddenField (value) {
    // hidden field (for future requests)
    this.hiddenFieldTarget.value = value.toString()

    // browser history (for navigation away and then returning back to this page)
    const url = new URL(window.location)
    url.searchParams.set("open_map", value.toString())
    window.history.pushState({}, "", url)
  }
}
