import LeafletController from "./leaflet_controller"
import "./../../stylesheets/application/components/override-vendors/_search_map_leaflet_override.sass"

export default class extends LeafletController {
  static targets = ["item"]
  minPopupWidth = 300
  maxPopupWidth = 300

  displayMap () {
    this.itemTarget.classList.remove("hidden")
  }

  initializeLeaflet () {
    super.initializeLeaflet()
    if (this.unpaginatedAccommodationsUrlValue !== "") {
      fetch(this.unpaginatedAccommodationsUrlValue, {
        headers: { Accept: 'application/json' }
      })
        .then(response => response.json())
        .then(data => {
          this.markersDataValue = data
          this.loadMarkers()
          this.setMapCenter()

          this.boundOnHighlightSearchResult = this.onHighlightSearchResult.bind(this)
          document.addEventListener("highlight-search-result", this.boundOnHighlightSearchResult)
        })
    }
  }

  disconnect () {
    super.disconnect()
    document.removeEventListener("highlight-search-result", this.boundOnHighlightSearchResult)
  }

  findAccommodationMarker (accommodationId) {
    return this.markers.find(marker =>
      marker.options.accommodationId === accommodationId
    )
  }

  onHighlightSearchResult (event) {
    const marker = this.findAccommodationMarker(event.detail.accommodationId)
    if (!marker) {
      console.warn("Leaflet marker not found for:", event.detail)
      return
    }

    if (this.clusteredValue) { // clustered map
      const visibleParent = this.markerClusterGroup.getVisibleParent(marker)

      if (visibleParent === marker) { // flat marker on clustered map
        this.highlightMarker(marker)
      } else { // cluster marker
        this.highlightCluster(visibleParent)
        this.map.panInside(visibleParent.getLatLng(), { padding: this.autoPanPadding })
      }
    } else { // flat map
      this.highlightMarker(marker)
    }
  }
}
